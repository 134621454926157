import {
  DigimapViewer,
  TDGMMapViewerOptions,
} from '@digimap/digimap-core-viewer';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export function App() {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    let mode = (searchParams.get('mode') as any) || 'v1';
    let id = searchParams.get('venue') || '';
    let apiKey = searchParams.get('key') || '';

    if (window.location.hostname === 'hcj.digimap.ai') {
      mode = 'v2';
      id = '6ccda0ac-a265-4a85-bf70-9d15d2d4bc86';
      apiKey = 'GLGwj8TxGP-zYMjDM2bS6OnCcfW61QrmC4vcpm5KOEc';
    } else if (window.location.hostname === 'foodex.digimap.ai') {
      mode = 'v2';
      id = '8b65fc5f-cf3b-4399-8348-4c7e2464e6f9';
      apiKey = 'mt2gxq12_PbGOCwVEFbNQ1ivewEQkwvGUM2tV58vSDY';
    }

    const options: TDGMMapViewerOptions = {
      id: id,
      apiKey: apiKey,
      draftMode: (searchParams.get('type') || 'public') === 'draft',
      debugMode: searchParams.get('debug') === 'true',
      defaultLanguage: searchParams.get('lang') || 'en',
      dev: window.location.hostname.includes('dev'),
      mode: mode,
    };
    DigimapViewer.init(options);
  }, []);

  return (
    <div style={{ width: '100%', height: '100%' }} id="digimap-viewer"></div>
  );
}
